import React, { useState } from 'react';
import images from '../libs/images';
import Menu from './Menu';

const Footer = ({ home = 0 }) => {
  const [menu, setMenu] = useState(false);

  return (
    <>
      {home === 0 && (
        <>
          <div className={'left-menu fixed'}>
            <button className='hover-zoom pcMenuCol pcMenuBtn' onClick={() => setMenu(true)}>
              <img src={images.img3} className='w36' alt='' />
              <span>메뉴열기</span>
            </button>
            <a href='/schedule' className='hover-zoom pcMenuCol'>
              <img src={images.img15} className='w36' alt='' />
              <span>스케줄</span>
            </a>
          </div>
          <Menu visible={menu} onClose={() => setMenu(false)} />
        </>
      )}
      <div className='footer'>
        <div className='mt10 pc-flex-center'>
          <div className='flex-center'>
            <a href='/terms'>서비스 이용약관</a>
            <span className='colorf font12 mlr7'>│</span>
            <a href='/policy'>개인정보처리방침</a>
            <span className='colorf font12 mlr7'>│</span>
            <a href='/license'>저작권 정책</a>
            <span className='colorf font12 mlr7'>│</span>
            <a href='/notice'>공지사항</a>
          </div>
          <div className='flex-center pc-ml20'>
            <select className='w200 h40'>
              <option>관련사이트</option>
            </select>
          </div>
        </div>
        <div className='mt10 borderbe w-100'></div>
        <div className='flex-column-center flex1 footerBottomInner'>
          <div className='flex-row-center'>
            <a href='https://www.instagram.com/the_essene_official' className='h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img12} className='w36' alt='' />
            </a>
            <a href='https://www.facebook.com/the.essene' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img13} className='w36' alt='' />
            </a>
            <a href='https://www.youtube.com/@masterhealers' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img14} className='w36' alt='' />
            </a>
            <a href='https://halokim-healer.tistory.com/' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img92} className='w36' alt='' />
            </a>
          </div>
          <img src={images.logo2} className='mt20 w164' alt='' />
          <p className='mt20 pc-font14 colorf text-center'>에세네 프라임 컴패니<br/> 대표자 : 박경하 | 주소 : 서울특별시 성북구 성북로5길 45, 201호</p>
          <p className='pc-font14 colorf'>전화 : 02-741-7770 | 팩스 : 02-6455-7264</p>
          <p className='pc-font14 colorf'>사업자등록번호: 208-07-79570 | 통신판매업신고 : 제2023 -서울성북-0667 호</p>
          <p className='pc-font14 colorf'>이메일 : help@esseneprime.com</p>
        </div>
      </div>
    </>
  )
}

export default Footer
