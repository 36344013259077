import React from 'react';

const Login = React.lazy(() => import('./views/Login'));
const Register1 = React.lazy(() => import('./views/Register1'));
const Register2 = React.lazy(() => import('./views/Register2'));
const Register3 = React.lazy(() => import('./views/Register3'));
const FindIdPw = React.lazy(() => import('./views/FindIdPw'));
const Membership = React.lazy(() => import('./views/Membership'));
const Payment1 = React.lazy(() => import('./views/Payment1'));
const PayComplete1 = React.lazy(() => import('./views/PayComplete1'));
const MembershipPayComplete = React.lazy(() => import('./views/MembershipPayComplete'));
const Payment2 = React.lazy(() => import('./views/Payment2'));
const PayComplete2 = React.lazy(() => import('./views/PayComplete2'));
const PayCompleteTest = React.lazy(() => import('./views/PayCompleteTest'));
const ProgramPayComplete = React.lazy(() => import('./views/ProgramPayComplete'));
const About = React.lazy(() => import('./views/About'));
const PayChange = React.lazy(() => import('./views/PayChange'));
const MyPage = React.lazy(() => import('./views/MyPage'));
const Notice = React.lazy(() => import('./views/Notice'));
const NoticeDetail = React.lazy(() => import('./views/NoticeDetail'));
const Review = React.lazy(() => import('./views/Review'));
const ReviewAdd = React.lazy(() => import('./views/ReviewAdd'));
const ReviewEdit = React.lazy(() => import('./views/ReviewEdit'));
const ReviewDetail = React.lazy(() => import('./views/ReviewDetail'));
const Faq = React.lazy(() => import('./views/Faq'));
const Webzine = React.lazy(() => import('./views/Webzine'));
const WebzinePreview = React.lazy(() => import('./views/WebzinePreview'));
const WebzineIntro = React.lazy(() => import('./views/WebzineIntro'));
const WebzineList = React.lazy(() => import('./views/WebzineList'));
const WebzineListPreview = React.lazy(() => import('./views/WebzineListPreview'));
const WebzineDetail = React.lazy(() => import('./views/WebzineDetail'));
const Schedule = React.lazy(() => import('./views/Schedule'));
const Program = React.lazy(() => import('./views/Program'));
const ProgramPreview = React.lazy(() => import('./views/ProgramPreview'));
const ProgramDetail = React.lazy(() => import('./views/ProgramDetail'));
const Search = React.lazy(() => import('./views/Search'));
const Policy = React.lazy(() => import('./views/Policy'));
const Terms = React.lazy(() => import('./views/Terms'));
const License = React.lazy(() => import('./views/License'));
const PayCancel = React.lazy(() => import('./views/PayCancel'));
const EditUserInfo = React.lazy(() => import('./views/EditUserInfo'));
const InquiryAdd = React.lazy(() => import('./views/InquiryAdd'));
const ProgramPayFailed = React.lazy(()=> import('./views/ProgramPayFailed'))

const routes = [
  { path: '/login', name: 'Login', element: Login },
  { path: '/register1', name: 'Register1', element: Register1 },
  { path: '/register2', name: 'Register2', element: Register2 },
  { path: '/register3', name: 'Register3', element: Register3 },
  { path: '/findIdPw', name: 'FindIdPw', element: FindIdPw },
  { path: '/membership', name: 'Membership', element: Membership },
  { path: '/payment1', name: 'Payment', element: Payment1 },
  { path: '/payComplete1', name: 'PayComplete1', element: PayComplete1 },
  { path: '/membershipPayComplete', name: 'MembershipPayComplete', element: MembershipPayComplete },
  { path: '/payment2', name: 'Payment2', element: Payment2 },
  { path: '/payCompleteTest', name: 'PayCompleteTest', element: PayCompleteTest },
  { path: '/payComplete2', name: 'PayComplete2', element: PayComplete2 },
  { path: '/programPayComplete', name: 'ProgramPayComplete', element: ProgramPayComplete },
  { path: '/about', name: 'About', element: About },
  { path: '/payChange', name: 'PayChange', element: PayChange },
  { path: '/myPage', name: 'MyPage', element: MyPage },
  { path: '/notice', name: 'Notice', element: Notice },
  { path: '/noticeDetail', name: 'NoticeDetail', element: NoticeDetail },
  { path: '/review', name: 'Review', element: Review },
  { path: '/reviewAdd', name: 'ReviewAdd', element: ReviewAdd },
  { path: '/reviewDetail', name: 'ReviewDetail', element: ReviewDetail },
  { path: '/reviewEdit', name: 'ReviewEdit', element: ReviewEdit },
  { path: '/faq', name: 'Faq', element: Faq },
  { path: '/webzine', name: 'Webzine', element: Webzine },
  { path: '/webzinePreview', name: 'WebzinePreview', element: WebzinePreview },
  { path: '/webzineIntro', name: 'WebzineIntro', element: WebzineIntro },
  { path: '/webzineList', name: 'WebzineList', element: WebzineList },
  { path: '/webzineListPreview', name: 'WebzineListPreview', element: WebzineListPreview },
  { path: '/webzineDetail', name: 'WebzineDetail', element: WebzineDetail },
  { path: '/schedule', name: 'Schedule', element: Schedule },
  { path: '/program', name: 'Program', element: Program },
  { path: '/programPreview', name: 'ProgramPreview', element: ProgramPreview },
  { path: '/programDetail', name: 'ProgramDetail', element: ProgramDetail },
  { path: '/search', name: 'Search', element: Search },
  { path: '/policy', name: 'Policy', element: Policy },
  { path: '/terms', name: 'Terms', element: Terms },
  { path: '/license', name: 'License', element: License },
  { path: '/payCancel', name: 'PayCancel', element: PayCancel },
  { path: '/editUserInfo', name: 'EditUserInfo', element: EditUserInfo },
  { path: '/inquiryAdd', name: 'InquiryAdd', element: InquiryAdd },
  { path: '/programPayFailed', name: 'ProgramPayFailed', element: ProgramPayFailed },
];

export default routes;
