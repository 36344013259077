import React, { Component } from 'react';
import images from '../libs/images';

class PreLoadImage extends Component {
  constructor(props) {
    super(props);
    this.image = null;
  }

  componentDidMount() {
    const loaderImg = new Image();

    loaderImg.src = this.props.url;

    loaderImg.onload = () => {
      if(this.image){
      this.image.setAttribute('style', `background-image: url('${this.props.url}')`);
      this.image.classList.add('image-fade-in');
      }
    }
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className="image-loaded" ref={imageLoadedElem => this.image = imageLoadedElem}></div>
        <div className="image-preload" style={{ backgroundImage: 'url("'+images.img103+'")'}}></div>
      </div>
    )
  }
}

export default PreLoadImage;
